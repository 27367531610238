import styled from 'styled-components'

export default () => {
	return (
		<Container>
			<LogoImg src="/assets/images/logo-full-inverse.svg" title="Coming sooon!" />
		</Container >
	)
}

const Container = styled.div`
    background-color: var(--color-bg);
	display: flex;
	flex: 1 1 auto;
	height: 100vh;
	color: #fff;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const LogoImg = styled.img`
	min-width: 12em;
	width: 25%;
`